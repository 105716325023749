<template>
  <div class="space-y-6 mt-6">
    <PasswordChange></PasswordChange>

    <AmazonAPIAccess></AmazonAPIAccess>

    <AmazonAssociatesTrackingIds></AmazonAssociatesTrackingIds>

    <AmazonOneLink></AmazonOneLink>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PasswordChange from "@/components/Settings/PasswordChange";
import AmazonAPIAccess from "@/components/Settings/AmazonAPIAccess";
import AmazonAssociatesTrackingIds from "@/components/Settings/AmazonAssociatesTrackingIds";
import AmazonOneLink from "@/components/Settings/AmazonOneLink";

export default {
  name: "Settings",
  components: {
    AmazonOneLink,
    PasswordChange,
    AmazonAPIAccess,
    AmazonAssociatesTrackingIds
  },
  computed: {
    ...mapGetters({
      UserProfile: 'StateUserSettings',
      TrackingIds: 'StateTrackingIds',
    })
  },
  methods: {
    ...mapActions(['GetUserSettings', 'GetTrackingIds'])
  },
  created() {
    this.GetUserSettings()
    this.GetTrackingIds()
  }
}
</script>
