//import Vue from 'vue'
import { createApp, h } from "vue";
import App from './App.vue'
import router from './router'
import './index.css'
import store from '@/store'
//Vue.config.productionTip = false


const app = createApp({
  // root instance definition
  render: () => (h(App))
});

app.use(router)
app.use(store)
app.mount("#app");

/**
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
**/