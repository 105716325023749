<template>
  <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Amazon One-Link</h3>
        <p class="mt-1 text-sm text-gray-500">
          Update your Amazon One-Link
        </p>
      </div>
      <div class="md:mt-0 md:col-span-2">
        <div>
          <div class="">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Add Amazon One-Link
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                Paste your Amazon One-Link embed code here to internationalize affiliate links.
              </p>
            </div>
            <form @submit.prevent="submit" class="mt-5 sm:flex sm:items-center">
              <div class="w-full sm:max-w-xs">
                <label for="oneLink" class="sr-only">Embed Code</label>
                <input
                    v-model="appendHtml"
                    type="text"
                    name="oneLink"
                    id="oneLink"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                    border-gray-300 rounded-md" placeholder="Embed Code" />
              </div>
              <button type="submit" class="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                Save
              </button>
            </form>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>

import {mapActions} from "vuex";

export default {
  name: 'AmazonOneLink',
  components: {},
  data() {
    return {
      appendHtml: ""
    }
  },
  methods: {
    ...mapActions(
        ['UpdateAccount']
    ),
    submit() {
      this.UpdateAccount({'appendHtml': this.appendHtml})
    }
  }
}

</script>