<template>
  <div>
    <div>
      <label for="email" class="block text-sm font-medium text-gray-700">Show column when screen is at least this wide:</label>
      <div class="mt-1 flex rounded-md shadow-sm">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
          <select
              v-model="selectedColumn.columnSize"
              @change="updateColumnStyling"
              class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300">
            <option
                v-for="item in displaySizes"
                :key="item.value"
                :value="item.value">{{item.display}}</option>
          </select>
        </div>
        <button
            @click="isSizeModalOpen = true"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm
            font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1
            focus:ring-indigo-500 focus:border-indigo-500">
          <QuestionMarkCircleIcon
              class="h-5 w-5 text-gray-400"
              aria-hidden="true" />
        </button>
      </div>
    </div>

    <ConfirmModal
        :open="isSizeModalOpen"
        @confirmModal="isSizeModalOpen = false"
        :confirm-btn-text="'Close'">
      <template v-slot:header>
        Display Size Chart
      </template>
      <template v-slot:body>
        <div class="flex flex-col">
            <div class="py-2 align-middle inline-block sm:px-6 lg:px-8">
              <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="divide-y divide-gray-200">
                  <thead class="bg-gray-50">
                  <tr>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Screen Size
                    </th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Width
                    </th>
                    <th scope="col"
                        class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Devices
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, itemIdx) in tableDisplaySizes" :key="itemIdx"
                      :class="itemIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{ item.screen_size }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ item.width }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ item.devices }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>

      </template>
    </ConfirmModal>

  </div>
</template>

<script>
import { QuestionMarkCircleIcon } from '@heroicons/vue/solid'
import ConfirmModal from "@/components/Utils/ConfirmModal";

const DISPLAY_SIZES = [
  {
    'display': "Always Show",
    'value': "ExtraSmall",
  },
  {
    'display': "Small",
    'value': "Small"
  },
  {
    'display': "Medium",
    'value': "Medium"
  },
  {
    'display': "Large",
    'value': "Large"
  },
  {
    'display': "Extra Large",
    'value': "ExtraLarge"
  },
  {
    'display': "Hidden",
    'value': "Hidden"
  }
]

const TABLE_DISPLAY_SIZES = [
  {
    'screen_size': 'Always Show',
    'width': '≥ 0 px',
    'devices': 'portrait phones and wider'
  }, {
    'screen_size': 'Small',
    'width': '≥ 576 px',
    'devices': 'landscape phones and wider'
  }, {
    'screen_size': 'Medium',
    'width': '≥ 769 px',
    'devices': 'tablets and wider'
  }, {
    'screen_size': 'Large',
    'width': '≥ 992 px',
    'devices': 'desktops and wider'
  }, {
    'screen_size': 'Extra Large',
    'width': '≥ 1200 px',
    'devices': 'large desktops'
  }, {
    'screen_size': 'hide',
    'width': 'Hidden',
    'devices': 'none'
  }
]

export default {
  name: "SizeSelection",
  props: [
    'selectedColumn'
  ],
  components: {
    ConfirmModal,
    QuestionMarkCircleIcon
  },
  emits: [
      'updateColumnStyling'
  ],
  data() {
    return {
      displaySizes: DISPLAY_SIZES,
      tableDisplaySizes: TABLE_DISPLAY_SIZES,
      isSizeModalOpen: false,
    }
  },
  methods: {
    updateColumnStyling(e) {
      this.$emit('updateColumnSize', {column: this.selectedColumn, newColumnSize: e.target.value})
    }
  }
}
</script>