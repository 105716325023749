<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="Workflow"/>
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
        Register your account
      </h2>

    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="register">
          <div>
            <label for="firstName" class="block text-sm font-medium text-gray-700">
              First Name
            </label>
            <div class="mt-1">
              <input
                  v-model="form.firstName"
                  id="firstName"
                  name="firstName"
                  type="text"
                  required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
              <span class="text-sm text-red-500">{{formError.firstName}}</span>
            </div>
          </div>

          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">
              Email address
            </label>
            <div class="mt-1">
              <input
                  v-model="form.email"
                  id="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
              <span class="text-sm text-red-500">{{formError.email}}</span>

            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700">
              Password
            </label>
            <div class="mt-1">
              <input
                  v-model="form.password1"
                  id="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
              <span class="text-sm text-red-500">{{formError.password1}}</span>
            </div>
          </div>

          <div>
            <label for="passwordRepeat" class="block text-sm font-medium text-gray-700">
              Password Repeat
            </label>
            <div class="mt-1">
              <input
                  v-model="form.password2"
                  id="passwordRepeat"
                  name="passwordRepeat"
                  type="password"
                  autocomplete="current-password"
                  required=""
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm
                  placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"/>
              <span class="text-sm text-red-500">{{formError.password2}}</span>
              <span class="text-sm text-red-500">{{formError.nonFieldErrors}}</span>

            </div>
          </div>

          <div>
            <button type="submit"
                    class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Register
            </button>
          </div>
        </form>

      </div>
    </div>
    <InfoBanner
        :show="showEmailBanner"
    >Test</InfoBanner>
  </div>
</template>

<script>

import {mapActions} from "vuex";
import InfoBanner from "@/components/Utils/Banner/Info"

export default {
  name: "RegisterComponent",
  components: {
    InfoBanner
  },
  data() {
    return {
      showEmailBanner: true,
      emailBannerMessage: '',
      form: {
        email: "",
        firstName: "",
        password1: "",
        password2: ""
      },
      formError: {
        email: "",
        firstName: "",
        password1: "",
        password2: "",
        nonFieldErrors: ""
      }
    }
  },
  methods: {
    ...mapActions(['Register']),
    register() {
      this.Register(this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.formError = {
              email: "",
              firstName: "",
              password1: "",
              password2: "",
              nonFieldErrors: ""
            }

            this.emailBannerMessage = "Please confirm your mail address"
            this.showEmailBanner = true
          }
          )
          .catch(e => {
            this.showEmailBanner = false
            let data = e.response.data
            for(const k in data) {
              this.formError[k] = data[k][0]
            }
          })
    }
  }

}
</script>

<style scoped>

</style>