<template>
  <div>
    <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <WarningBanner
          :open="showError"
          @closeBanner="showError = false">
        {{ errorMessage }}
      </WarningBanner>
      <div class="max-w-md w-full space-y-8">
        <div>
          <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="Workflow"/>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verifying email
          </h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarningBanner from "@/components/Utils/Banner/Warning"
import {reactive, onMounted} from "vue";
import {useRoute, useRouter} from "vue-router";
import axios from "axios";

export default {
  name: "Verify",
  components: {
    WarningBanner,
  },
  setup() {
    const route = useRoute()
    const router = useRouter()

    let state = reactive({
      showError: false,
      errorMessage: ""
    })

    onMounted(() => {
      // eslint-disable-next-line no-unused-vars
      axios.get('account/activate/' + route.params.verifyId).then(res => {
        console.log("VERIFY")
        console.log(res)
        router.push("/login");
        // eslint-disable-next-line no-unused-vars
      }).catch(e => {
            state.showError = true
            state.errorMessage = 'Unknown verification key.'
          }
      )
    })

    return state
  }
}
</script>