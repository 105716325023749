<template>
  <BtnBase
      :size="size"
      :classes="'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500'">
    <slot></slot>
  </BtnBase>
</template>

<script>
import BtnBase from "@/components/Utils/Btn/Base";

export default {
  name: "BtnSecondary",
  props: ['size'],
  components: {
    BtnBase
  },
}
</script>