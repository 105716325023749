import axios from 'axios';

const DEFAULT_CHART_ROWS = [{
    "id": -1,
    "name": "Clicks",
    "columnType": "Clicks",
    "columnSize": "ExtraSmall"
}, {
    "id": -2,
    "name": "Top Pick",
    "columnType": "TopPick",
    "columnSize": "ExtraSmall"
}]

const state = {
    charts: null,
    newChart: null,
    themes: null,
    selectedChart: null,
    selectedChartHeader: null
};

const getters = {
    StateCharts: state => state.charts,
    StateNewChart: state => state.newChart,
    StateThemes: state => state.themes,
    StateSelectedChart: state => state.selectedChart,
    StateHeaderSelectedChart: state => state.selectedChartHeader
};

// Actions are functions that are used to commit a mutation to change the state or can be used to dispatch
const actions = {
    GetChart({commit}, uid) {
        return axios.get('/charts/' + uid).then(res => {
            commit('setSelectedChart', res.data)
            return res
        })
    },
    async GetCharts({commit}) {
        let res = await axios.get('charts')
        await commit('setCharts', res.data)
    },
    async UpdateColumnOrder({commit}, data) {
        commit('updateChartHeaderOrder', data['columnOrder'])
        let res = await axios.patch('charts/' + data['uid'],
            {
                'ColumnOrder': data['columnOrder']
            }
        )
        await commit('setSelectedChart', res.data)
    },
    async CreateChart({commit, getters}) {
        let res = await axios.post('charts',
            {
                title: "New Chart",
                trackingId: getters.StateDefaultTrackingId['value']
            })
        await commit('setNewChart', res.data)
        return res.data
    },
    CopyChart({commit, getters}, chartUid) {
        return axios.post('charts/' + chartUid + '/copy',  {
            title: "New Chart",
            trackingId: getters.StateDefaultTrackingId['value']
        }).then(res => {
            commit('setSelectedChart', res.data)
            return res.data
        })
    },
    async GetThemes({commit}) {
        let res = await axios.get('themes/')
        await commit('setThemes', res.data)
    },
    async DeleteChart({commit}, chartId) {
        await axios.delete('charts/' + chartId)
        let res = await axios.get('charts')
        await commit('setCharts', res.data)
    },
    async UpdateTopPick({commit}, data) {

        commit('updateChartTopPick', data['topPick'])

        let res = await axios.patch('charts/' + data['uid'],
            {
                'topPick': data['topPick']
            })
        await commit('setCharts', res.data)
    },
    // eslint-disable-next-line no-unused-vars
    async UpdateCell({commit}, data) {
        // I need to update hte cell heare
        //commit('updateChartItem', data['request'])
        await axios.patch('cells/' + data['uid'], data['request'])
    },
    async UpdateItem({commit}, data) {
        commit('updateChartItem', data['item'])
        let res = await axios.patch('items/' + data['uid'], data['request'])
        commit('updateChartItem', res)

    },
    UpdateColumn({commit}, data) {
        commit('updateColumnSize', {columnId: data['uid'], column:data['request']})
        return axios.put('columns/' + data['uid'], data['request'])
    },
    // eslint-disable-next-line no-unused-vars
    AddNewColumn({commit}, data) {
        return axios.post('columns/', data )
    },
    // eslint-disable-next-line no-unused-vars
    DeleteColumn({commit}, chartId) {
        return axios.delete('columns/' + chartId)
    },
    DeleteItem({commit}, itemId) {

        commit('removeItem', itemId)
        return axios.delete('items/' + itemId)
    },
    GetSearchtermFromAmazon({getters}, {searchTerm, page}) {
        let region = getters.StateAmazonApiAccess.amazonRegion
        let defaultTrackingId = getters.StateDefaultTrackingId['value']
        defaultTrackingId = getters.StateSelectedChart.trackingId
        if (page !== 0) {
            return axios.get(encodeURI(`search?keywords=${searchTerm}&region=${region}&tracking_id=${defaultTrackingId}&extra=${page}`))
        } else {
            return axios.get(encodeURI(`search?keywords=${searchTerm}&region=${region}&tracking_id=${defaultTrackingId}`))
        }
    },
    // eslint-disable-next-line no-unused-vars
    AddNewItemToChart({commit}, item) {
        return axios.post('items', item)
    },
    // eslint-disable-next-line no-unused-vars
    UpdateChart({commit}, {chartId, data}) {
        return axios.patch('charts/' + chartId, data).then(
            res => commit('setSelectedChart', res['data'])
        )
    },
    // eslint-disable-next-line no-unused-vars
    CreateNewTheme({commit}, data) {
        axios.post('themes/', data)
    }

};

const mutations = {
    setCharts(state, charts) {
        state.charts = charts
    },
    setSelectedChart(state, chart) {
        state.selectedChart = chart

        let result = DEFAULT_CHART_ROWS

        let columnOrder = chart['columnOrder']
        let customColumns = chart['customColumns']
        result = result.concat(columnOrder.map(x => customColumns.find(y => y['id'] === x)))

        state.selectedChartHeader = result

    },
    setNewChart(state, newChart) {
        state.newChart = newChart
    },
    setThemes(state, themes) {
        state.themes = themes
    },
    updateChartHeaderOrder(state, columnOrder) {
        state.selectedChart.columnOrder = columnOrder

        let headers = DEFAULT_CHART_ROWS
        let customColumns = state.selectedChart.customColumns
        headers = headers.concat(columnOrder.map(x => customColumns.find(y => y['id'] === x)))

        state.selectedChartHeader = headers
    },
    updateChartItem(state, item) {
        let items = state.selectedChart.items
        state.selectedChart.items = items.map(x => x.uid === item.uid ? item : x)
    },
    updateChartTopPick(state, itemUid) {
        state.selectedChart.topPick = itemUid
    },
    updateColumnSize(state, {columnId, column}) {
        state.selectedChart.customColumns = state.selectedChart.customColumns.map(x => {
            if (x['id'] === columnId) {
                x['columnSize'] = column['columnSize']
                x['name'] = column['name']
            }
            return x
        })
    },
    removeItem(state, itemId) {
        state.selectedChart.items = state.selectedChart.items.filter(x => x['uid'] !== itemId)
    }

};

export default {
    state,
    getters,
    actions,
    mutations
};
