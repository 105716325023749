<template>
  <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Amazon Associates Tracking IDs</h3>
        <p class="mt-1 text-sm text-gray-500">
          Update your Default Amazon Associates Tracking IDs and add new ones
        </p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <div>
          <ul class="-my-5 divide-y divide-gray-200">
            <li v-for="trackingId in TrackingIds" :key="trackingId.id" class="py-4">
              <div class="flex items-center font-medium space-x-4">
                <div
                    class="flex-1 min-w-0">
                  <p
                      @click="updateDefaultTrackingId(trackingId)"
                      :class="trackingId.isDefault ? 'font-bold text-blue-600' : 'text-gray-600 font-medium'"
                      class="text-sm truncate cursor-pointer">
                    <span
                        v-if="trackingId.dateFailed"
                        title="This Tracking ID is invalid">
                      <ExclamationCircleIcon
                          class="w-4 h-4 inline-block">
                      </ExclamationCircleIcon>
                    </span>
                    {{ trackingId.value }}
                  </p>
                  <p
                      v-if="trackingId.isDefault"
                      class="text-sm text-gray-500 truncate">
                    Default
                  </p>
                </div>
                <div>
                  <BtnDanger
                      @click="deleteTrackingId(trackingId)"
                      :size="'xs'">
                    Remove
                  </BtnDanger>
                </div>
              </div>
            </li>
          </ul>

          <div class="pt-5">
            <div class="px-4 py-5 sm:p-6 md:px-0">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                Add New Tracking Id
              </h3>
              <div class="mt-2 max-w-xl text-sm text-gray-500">
                <p>
                  Add a new amazon Associates Tracking ID
                </p>
              </div>
              <div class="mt-5 sm:flex sm:items-center">
                <div class="w-full sm:max-w-xs">
                  <label for="tracking_id" class="sr-only">TrackingId</label>
                  <input
                      type="text"
                      v-model="trackingId"
                      name="email"
                      class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                        border-gray-300 rounded-md"
                      placeholder="New Tracking ID"/>
                </div>
                <button @click="submit"
                        class="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <DangerModal
        :open="isDeleteModalOpen"
        @cancelModal="isDeleteModalOpen = false"
        @confirmModal="onConfirmDeleteChart">
      <template v-slot:header>
        Do you want to delete this Tag
      </template>
      <template v-slot:body>
        Are you sure you want to delete {{ selectedTrackingTag.value }}
      </template>
    </DangerModal>

  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BtnDanger from '@/components/Utils/Btn/Danger'
import DangerModal from "@/components/Utils/DangerModal";
import {ExclamationCircleIcon} from "@heroicons/vue/solid";


export default {
  name: 'AmazonAssociatesTrackingIds',
  components: {
    BtnDanger,
    DangerModal,
    ExclamationCircleIcon
  },
  computed: {
    ...mapGetters({
      TrackingIds: 'StateTrackingIds'
    })
  },
  methods: {
    ...mapActions(
        [
          'AddNewTag',
          'UpdateDefaultTag',
          'DeleteTag'
        ]),
    submit() {
      this.AddNewTag(this.trackingId)
    },
    updateDefaultTrackingId(trackingTag) {
      this.UpdateDefaultTag(trackingTag.id)
          .catch(e => {
            console.log(e)
          })
    },
    onConfirmDeleteChart() {
      this.DeleteTag(this.selectedTrackingTag.id)
          .catch(e => {
            console.log(e)
          })
      this.isDeleteModalOpen = false
      this.selectedTrackingTag = ''
    },
    deleteTrackingId(trackingTag) {
      this.selectedTrackingTag = trackingTag
      this.isDeleteModalOpen = true
    }
  },
  data() {
    return {
      trackingId: null,
      selectedTrackingTag: null,
      isDeleteModalOpen: false
    }
  },
}

</script>