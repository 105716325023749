<template>
  <div v-if="trackingIds">
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
        {{chart.trackingId}}
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems class="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div class="py-1">
          <MenuItem
              @click="updateTrackingId(chart.uid, trackingId.value)"
              v-for="trackingId in trackingIds"
              :key="trackingId.id">
              <div
                  :class="[trackingId.value === chart.trackingId ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'cursor-pointer block px-4 py-2 text-sm']">
                <span
                    v-if="trackingId.dateFailed"
                    title="This Tracking ID is invalid">
                      <ExclamationCircleIcon
                          class="w-4 h-4 inline-block">
                      </ExclamationCircleIcon>
                </span>

                {{trackingId.value}}
                <br>
                <span v-if="trackingId.isDefault" class="text-xs">Default</span>

              </div>
          </MenuItem>
        </div>
        <div class="py-1">
          <router-link :to="{name: 'settings'}"
            class="text-gray-700 cursor-pointer block px-4 py-2 text-sm">
            Manage Tracking IDs
          </router-link>
        </div>
      </MenuItems>
    </transition>
  </Menu>
  </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import {ChevronDownIcon, ExclamationCircleIcon} from '@heroicons/vue/solid'

export default {
  name: "TrackingIdPicker",
  props: ['trackingIds', 'chart'],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ExclamationCircleIcon
  },
  methods: {
    updateTrackingId(chartId, trackingId) {
      this.$emit('updateTrackingId', {chartId, trackingId})
    }
  }
}
</script>