function getCurrencyForCountry(country) {
    const countries = {
        "AE": "AED",
        "AU": "AUD",
        "BR": "BRL",
        "CA": "CAD",
        "DE": "€",
        "ES": "€",
        "FR": "€",
        "IN": "INR",
        "IT": "€",
        "JP": "JPY",
        "MX": "MXN",
        "SG": "SGD",
        "TR": "TRY",
        "UK": "£",
        "US": "$",
    }
    return countries[country]
}

export {getCurrencyForCountry}
