<template>
  <TransitionRoot
      as="template"
      :show="open">
    <div class="text-center z-10 fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-yellow-50 shadow-lg sm:p-3">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <p class="ml-3 font-medium text-yellow-800 truncate block w-full text-center">
                <slot></slot>
              </p>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                  @click="closeBanner"
                  type="button"
                  class="-mr-1 flex p-2 rounded-md hover:bg-yellow-400 bg-yellow-400 focus:outline-none focus:ring-2
                  focus:ring-white">
                <span class="sr-only">Dismiss</span>
                <XIcon class="h-6 w-6 text-white" aria-hidden="true"/>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </TransitionRoot>
</template>

<script>

import {XIcon} from '@heroicons/vue/outline'
import {TransitionRoot} from "@headlessui/vue";

export default {
  name: "WarningBanner",
  props: {
    open: Boolean,
  },
  components: {
    TransitionRoot,
    XIcon,
  },
  methods: {
    closeBanner() {
      this.$emit('closeBanner')
    }
  }
}
</script>