<template>
  <BtnBase
      :size="size"
      :classes="'border-transparent text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500'">
    <slot></slot>
  </BtnBase>
</template>

<script>
import BtnBase from "@/components/Utils/Btn/Base";

export default {
  name: "BtnPrimary",
  props: ['size'],
  components: {
    BtnBase
  },
}
</script>