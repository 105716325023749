<template>
  <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Amazon Access Key ID</h3>
        <p class="mt-1 text-sm text-gray-500">
          Update your Amazon Product Advertising API Access Key
        </p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <div v-if="!AmazonApiAccess">Loading...</div>
        <form v-else @submit.prevent="submit" >
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-4">
              <label for="email_address" class="block text-sm font-medium text-gray-700">Amazon Access Key ID</label>
              <input v-model="AmazonApiAccess.publicKey" type="text" name="email_address" id="old_password" autocomplete="email"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
            </div>
            <div class="col-span-6 sm:col-span-4">
              <label for="email_address" class="block text-sm font-medium text-gray-700">Amazon Secret Access Key</label>
              <input v-model="AmazonApiAccess.privateKey" type="text" name="email_address" id="new_password" autocomplete="email"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"/>
            </div>

            <div class="col-span-6 sm:col-span-3">
              <label for="country" class="block text-sm font-medium text-gray-700">Default Search Region</label>
              <select v-model="AmazonApiAccess.amazonRegion" id="country" name="country" autocomplete="country" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <option>AE</option>
                <option>AU</option>
                <option>BR</option>
                <option>CA</option>
                <option>DE</option>
                <option>ES</option>
                <option>FR</option>
                <option>IN</option>
                <option>IT</option>
                <option>JP</option>
                <option>MX</option>
                <option>SG</option>
                <option>TR</option>
                <option>UK</option>
                <option>US</option>
              </select>
            </div>

          </div>

          <div class="pt-5">
            <div class="mt-4 space-y-4">
              <div class="flex items-start">
                <div class="h-5 flex items-center">
                  <input id="comments" v-model="AmazonApiAccess.hidePriceless" name="comments" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                </div>
                <div class="ml-3 text-sm">
                  <label for="comments" class="font-medium text-gray-700">Hide Items that are Out of Stock</label>
                </div>
              </div>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button type="submit"
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Save
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
    <WarningBanner
        :open="showError"
        @closeBanner="showError = false">
      {{ errorMessage }}
    </WarningBanner>
  </div>

</template>

<script>

import {mapGetters, mapActions} from "vuex";
import WarningBanner from "@/components/Utils/Banner/Warning";

export default {
  name: 'AmazonAPIAccess',
  components: {
    WarningBanner
  },
  data() {
    return {
      showError: false,
      errorMessage: ''
    }
  },
  computed: {
    ...mapGetters({
      AmazonApiAccess: 'StateAmazonApiAccess'
    })
  },
  methods: {
    ...mapActions(
        ['UpdateAmazonApiAccess']
    ),
    submit() {
      this.UpdateAmazonApiAccess(this.AmazonApiAccess)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.showError = false
          })
          .catch(e => {
            this.showError = true
            this.errorMessage = e.response.data['message']
          })
    }
  }
}

</script>