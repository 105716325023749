import axios from 'axios';

const state = {
    user: null,
};

const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
};

const actions = {
    LogIn({commit}, user) {
        return axios.post(
            'account/login/',
            {
                email: user.username,
                password: user.password
            }).then(res => {
                commit('setUser', res.data)
                axios.defaults.headers.common['Authorization'] = `Token ${res.data.token}`
                return res
            })
    },
    async LogOut({commit}) {
        commit('removeUser')
    },
    ResetPassword({commit}, email) {
        return axios.post(
            '/account/password/',
            {
                email: email
            }).then(res => {
                commit('removeUser')
                return res
            }
        )
    }
};

const mutations = {
    setUser(state, username) {
        state.user = username
    },
    removeUser(state) {
        state.user = null
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
