import axios from "axios";

const state = {
    userSettings: null,
    trackingIds: null,
    passwordUpdate: null,
    amazonApiAccess: null,
    associateTrackingIds: null,
    billingData: null,
    defaultTrackingId: null
}

const getters = {
    StateUserSettings: state => state.userSettings,
    StateTrackingIds: state => state.trackingIds,
    StatePasswordUpdate: state => state.passwordUpdate,
    StateAmazonApiAccess: state => state.amazonApiAccess,
    StateBillingDetails: state => state.billingData,
    StateDefaultTrackingId: state => state.defaultTrackingId
}

const actions = {
    async GetUserSettings({commit}) {
        let res = await axios.get(
            'account/me'
        )

        await commit('setUserSettings', res.data)
    },
    async GetTrackingIds({commit}) {
        let res = await axios.get('account/tracking-ids')
        await commit('setUserTrackingIds', res.data)
    },
    UpdatePassword({commit}, passwordData) {
        // {oldPassword, password1, password2}
        return axios.post(
            'account/password',
            passwordData
        ).then(res => {
            commit('setUserPassword', res.data)
        })

    },
    UpdateAmazonApiAccess({commit}, apiAccessData) {
        return axios.post(
            'account/amazon-credentials',
            apiAccessData
        ).then(res => {
            commit('setAmazonApiAccess', res.data)
        })
    },
    async AddNewTag({commit}, trackingId) {
        let res = await axios.post('account/tracking-ids', {value: trackingId})

        await commit('addNewTrackingId', res.data)
    },
    async GetBillingDetails({commit}) {
        let res = await axios.get('account/billing')

        await commit('setBillingInfo', res.data)
    },
    UpdateDefaultTag({commit}, tagId) {
        return axios.patch(
            'account/tracking-ids/' + tagId + '/default'
            // eslint-disable-next-line no-unused-vars
        ).then(res => {
            return axios.get('account/tracking-ids')
        }).then(res => {
            commit('setUserTrackingIds', res.data)
        })
    },
    DeleteTag({commit}, tagId) {
        return axios.delete(
            'account/tracking-ids/' + tagId
            // eslint-disable-next-line no-unused-vars
        ).then(res => {
            return axios.get('account/tracking-ids')
        }).then(res => {
            commit('setUserTrackingIds', res.data)
        })
    },
    UpdateAccount({commit}, data) {
        return axios.patch(
            'account/me',
            data
        ).then(res => {
            commit('setUserSettings', res.data)
        })
    },
    Register({commit}, data) {
        return axios.post(
            'account/register',
            data
        ).then(res => {
            console.log(res)
            commit('setUserSettings', res.data)
        })
    }
}

const mutations = {
    setUserSettings(state, settings) {
        // propagate the associateTrackingIds data
        state.userSettings = settings

        const credentials = settings["credentials"]
        state.amazonApiAccess = {
            hidePriceless: settings["hidePriceless"],
            amazonRegion: settings["amazonRegion"],
            publicKey: credentials ? credentials["publicKey"]: '',
            privateKey: credentials ? credentials["privateKey"]: '',
        }
    },
    setUserTrackingIds(state, trackingIds) {
        state.trackingIds = trackingIds

        trackingIds.forEach(x => {
                if(x.isDefault)
                    state.defaultTrackingId = x
        })
    },
    setUserPassword(state, data) {
        state.passwordUpdate = data
    },
    setAmazonApiAccess(state, apiAccess) {
        state.amazonApiAccess = apiAccess
    },
    addNewTrackingId(state, tracking_id) {
        state.trackingIds.push(tracking_id)
    },
    setBillingInfo(state, billing_data) {
        state.billingData = billing_data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}