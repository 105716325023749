<template>
  <div class="bg-white shadow mt-6 px-4 py-5 mt-4 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="col-span-3">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Current Plan</h3>
        <p class="mt-1 text-sm text-gray-500">
          {{currentPlan.price}} - {{currentPlan.limit}} tables
          <br>
          Please contact <a class="text-blue-800" href="http://www.tablelabs.com/support">support</a> to update billing preferences.
        </p>
      </div>
    </div>
  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

const PLANS = {
  '-1': { price: '-', limit: '-' },
  '0': { price: '$9/month', limit: 20 },
  '1': { price: '$27/month', limit: 100 },
  '2': { price: '$67/month', limit: 'Unlimited' },
  '3': { price: '$79/year', limit: 20 },
  '4': { price: '$249/year', limit: 100 },
  '5': { price: '$599/year', limit: 'Unlimited' },
  '6': { price: '$7/month', limit: 30 },
  '7': { price: '$10/month', limit: 100 },
  '8': { price: '$19/month', limit: 'Unlimited' },
  '9': { price: '$67/year', limit: 30 },
  '10': { price: '$97/year', limit: 100 },
  '11': { price: '$197/year', limit: 'Unlimited' },
  '12': { price: '$97', limit: 30 },
  '13': { price: '$197', limit: 100 },
  '14': { price: '$297', limit: 'Unlimited' },
  '15': { price: '$10/month', limit: 100 },
  '16': { price: '$47/year', limit: 30 },
  '17': { price: '$67/year', limit: 100 },
  '18': { price: '$97/year', limit: 1000 },
  '19': { price: '$67/year', limit: 30 },
  '20': { price: '$87/year', limit: 100 },
  '21': { price: '$117/year', limit: 1000 },
  '22': { price: '$297', limit: 1000 },
  '23': { price: '$27/year', limit: 30 },
  '24': { price: '$47/year', limit: 100 },
  '25': { price: '$67/year', limit: 1000 },
  '26': { price: '$37/year', limit: 100 },
  '27': { price: '$47/year', limit: 1000 }
}

export default {
  name: "Billing",
  data() {
    return {
      plans: PLANS,
    }
  },
  computed: {
    ...mapGetters({
      BillingDetails: 'StateBillingDetails'
    }),
    currentPlan() {
      if(this.BillingDetails) {
        return this.plans[this.BillingDetails['plan']]
      } else {
        return {
          price: '',
          limit: ''
        }
      }
    }
  },
  methods: {
    ...mapActions(['GetBillingDetails'])
  },
  mounted() {
    this.GetBillingDetails()
  }
}
</script>
