<template>
  <div class="mt-6">
    <div class="mb-6">
      <input
          type="text"
          v-model="searchTerm"
          class="shadow-sm max-w-sm focus:ring-indigo-500 focus:border-indigo-500 inline-block mr-2 w-full sm:text-sm border-gray-300
        rounded-md"/>
      <BtnPrimary
          :size="'md'"
          @click="search(searchTerm)">
        Search
      </BtnPrimary>

      <router-link :to="{ name: 'editChart', params: { chartId: $route.params.chartId }}">
        <BtnSecondary
            :size="'md'"
            class="ml-4 float-right">
          Done
        </BtnSecondary>
      </router-link>
    </div>

    <div class="bg-white shadow overflow-hidden sm:rounded-md">
      <ul class="divide-y divide-gray-200">
        <li v-for="application in searchResults" :key="application.id">
          <div class="flex items-center px-4 py-4 sm:px-6">
            <div class="min-w-0 flex-1 flex items-center">
              <div class="flex-shrink-0">
                <img class="h-24 w-24 rounded-md" :src="application.image" alt=""/>
              </div>
              <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <a :href="application.url"
                     class="text-sm block font-medium text-indigo-600 truncate">{{ application.title }}</a>
                  <div>
                    <p
                        class="inline shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium
                        rounded-full text-gray-700 bg-white bg-green-100 text-green-800">
                      {{ application.price }} {{ getCurrency(application.amazonRegion) }}
                    </p>
                  </div>
                  <p class="mt-2 flex items-center text-sm text-gray-500">
                    <span class="">{{ application.description }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div>
              <BtnPrimary
                  :size="'md'"
                  @click="addItemToChart(application, $route.params.chartId)">
                Add Product
              </BtnPrimary>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <BtnPrimary
        v-if="searchResults !== []"
        :size="'md'"
        @click="showMore(searchTerm)"
        class="mt-6 mb-6 float-right">
      Show More
    </BtnPrimary>

    <InfoBanner
        :hide-dismiss="true"
        :show="showInfoBanner">
      {{ infoBannerMsg }}
    </InfoBanner>

    <WarningBanner
        :open="showErrorBanner"
        @closeBanner="showErrorBanner = false">
      {{ errorBannerMsg }}
    </WarningBanner>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BtnPrimary from '@/components/Utils/Btn/Primary'
import BtnSecondary from '@/components/Utils/Btn/Secondary'
import {getCurrencyForCountry} from "@/components/Utils/getCurrency"
import InfoBanner from "@/components/Utils/Banner/Info"
import WarningBanner from "@/components/Utils/Banner/Warning"


export default {
  name: "ProductSearch",
  components: {
    BtnPrimary,
    BtnSecondary,
    InfoBanner,
    WarningBanner
  },
  data() {
    return {
      nextPage: 0,
      oldSearchTerm: null,
      searchTerm: null,
      searchResults: [],
      showInfoBanner: false,
      infoBannerMsg: '',
      showErrorBanner: false,
      errorBannerMsg: ''
    }
  },
  methods: {
    ...mapActions([
      'GetUserSettings',
      'GetTrackingIds',
      'GetSearchtermFromAmazon',
      'AddNewItemToChart'
    ]),
    getCurrency(country) {
      return getCurrencyForCountry(country)
    },
    search(searchTerm) {
      this.showInfoBanner = true
      this.infoBannerMsg = 'Loading Products'
      if (this.oldSearchTerm !== null) {
        if (this.oldSearchTerm !== searchTerm) {
          this.oldSearchTerm = searchTerm
          this.nextPage = 0
        }
      } else {
        this.oldSearchTerm = searchTerm
      }
      this.GetSearchtermFromAmazon({searchTerm, page: this.nextPage}).then(x => {
        this.searchResults = x['data']
        this.nextPage += 1
        this.showInfoBanner = false
        this.infoBannerMsg = ''
      }).catch(e => {
        this.errorBannerMsg = e.response.data.message
        this.showErrorBanner = true
      })
    },
    showMore(searchTerm) {
      this.showInfoBanner = true
      this.infoBannerMsg = 'Loading Products'

      this.GetSearchtermFromAmazon({searchTerm, page: this.nextPage}).then(x => {
        this.searchResults = this.searchResults.concat(x['data'])
        this.nextPage += 1
        this.showInfoBanner = false
        this.infoBannerMsg = ''
      }).catch(e => {
        this.errorBannerMsg = e.response.data.message
        this.showErrorBanner = true
      })
    },
    addItemToChart(item, chartId) {
      let relevant_keys = ["uid", "amazonRegion", "asin", "image", "title", "reviewsUrl", "price", "url", "description", "shortDescription"]
      let newItem = {}

      relevant_keys.forEach(x => {
        newItem[x] = item[x]
      })
      newItem['position'] = 0
      newItem['chart'] = chartId

      this.AddNewItemToChart(newItem).then(x => {
        this.searchResults = this.searchResults.filter(item => item['uid'] !== x['data']['uid'])
      }).catch(err => {
        console.log(err)
      })

    }
  },
  computed: {
    ...mapGetters({
      TrackingIds: 'StateTrackingIds',
      DefaultTrackingId: 'StateDefaultTrackingId',
    }),
  },
  created() {
    this.GetUserSettings()
    this.GetTrackingIds()
  }
}
</script>

<style scoped>

</style>