import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import chart from './modules/chart';
import account from "@/store/modules/account";

import { createStore } from 'vuex'

import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;


export default createStore({
    modules: {
        auth,
        chart,
        account
    },
    plugins: [createPersistedState({
        paths: ['auth'],
        getState: (key) => {
            let data = JSON.parse(localStorage.getItem(key))
            if(data && data.auth && data.auth.user ) {
                console.log(data)
                axios.defaults.headers.common['Authorization'] = `Token ${data.auth.user.token}`

                axios.get(
                    'account/me'

                ).then(res => console.log(res)
                    // eslint-disable-next-line no-unused-vars
                ).catch(err => {
                    window.localStorage.removeItem(key)
                    data = null
                })
            }
            return data
        },
    })],
})