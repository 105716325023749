<template>
  <div class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <div>
        <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="Workflow" />
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Forgot Password
        </h2>
        <p class="mt-6 text-center text-gray-700">
          Please enter your email address, and we'll send you a link that lets you reset your password.
        </p>

      </div>
      <form class="mt-8 space-y-6" @submit.prevent="resetPassword">
        <input type="hidden" name="remember" value="true" />
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input
                v-model="email"
                id="email-address"
                name="email"
                type="email"
                autocomplete="email"
                required=""
                class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300
                placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500
                focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address" />
          </div>
        </div>


        <div>
          <button
              @click="resetPassword(email)"
              type="submit"
              class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium
              rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2
              focus:ring-offset-2 focus:ring-indigo-500">
            Send Password Reset Email
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ForgotPassword",
  data() {
    return {
      email: '',
      showError: false,
      errorMessage: ''
    }
  },
  methods: {
    ...mapActions(['ResetPassword', 'LogIn']),
    resetPassword(email) {
      this.ResetPassword(email)
          .then(res => console.log(res))
          // eslint-disable-next-line no-unused-vars
          .catch(e => {
            this.errorMessage = "The server couldn't find the requested data."
            this.showError = true
          })
    }
  }
}
</script>

<style scoped>

</style>