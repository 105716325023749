<template>
  <div>
    <div class="w-full text-right mb-5">
      <BtnPrimary
          :size="'md'"
          @click="createNewChart">
        Create New Chart
      </BtnPrimary>
    </div>

    <Chart
        :orderKey="orderKey"
        :sortOrder="sortOrder"
        :charts="sortedCharts"
        @sortBy="onSortBy"
        @copyChart="onCopyChart"
        @deleteChart="onDeleteChart">
    </Chart>


    <DangerModal
        :open="isDeleteModalOpen"
        @cancelModal="isDeleteModalOpen = false"
        @confirmModal="onConfirmDeleteChart">
      <template v-slot:header>
        Do you want to delete this chart
      </template>
      <template v-slot:body>
        Are you sure you want to delete the chart
      </template>
    </DangerModal>

    <InfoBanner
        :hide-dismiss="true"
        :show="showInfoBanner">
      {{ infoBannerMsg }}
    </InfoBanner>

    <WarningBanner
        :open="showErrorBanner"
        @closeBanner="showErrorBanner = false">
      {{ errorBannerMsg }}
    </WarningBanner>

  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import Chart from "@/components/Dashboard/Chart";
import BtnPrimary from "@/components/Utils/Btn/Primary";
import DangerModal from "@/components/Utils/DangerModal";
import orderBy from 'lodash.orderby'
import InfoBanner from "@/components/Utils/Banner/Info";
import WarningBanner from "@/components/Utils/Banner/Warning";


export default {
  name: "Dashboard",
  components: {
    Chart,
    BtnPrimary,
    DangerModal,
    InfoBanner,
    WarningBanner
  },
  data() {
    return {
      isDeleteModalOpen: false,
      chartToDelete: null,
      orderKey: 'Name',
      sortOrder: 'asc',
      showInfoBanner: false,
      infoBannerMsg: '',
      showErrorBanner: false,
      errorBannerMsg: ''
    }
  },
  computed: {
    ...mapGetters({
      Charts: "StateCharts",
      NewChart: "StateNewChart"
    }),
    sortedCharts() {
      switch(this.orderKey) {
        case 'Name':
          return orderBy(this.Charts, 'title', this.sortOrder)
        case 'Views':
          return orderBy(this.Charts, 'viewCount', this.sortOrder)
        case 'Clicks':
          return orderBy(this.Charts.map(x =>{
            x['totalClicks'] = x['items'].reduce((x,y) => x + y['clickCount'], 0)
            return x
          }), 'totalClicks', this.sortOrder)
        case 'CTR':
          return orderBy(this.Charts.map(x => {
            if (x.viewCount === 0){
              x['ctr'] = 0
            } else {
              let chartClicks = x.items.reduce((acc, curr) => acc+curr.clickCount, 0)
              x['ctr'] = chartClicks/x.viewCount
            }
            return x
          }), 'ctr', this.sortOrder)
        case 'TRACKING ID':
          return orderBy(this.Charts, 'trackingId', this.sortOrder)
        case 'UPDATED':
          return orderBy(this.Charts, 'lastUpdated', this.sortOrder)
        case 'EPC':
          return orderBy(this.Charts, 'epc', this.sortOrder)
        case 'EARNINGS':
          return orderBy(this.Charts.map(x => {
            x['earnings'] = x.items.reduce((acc, curr) => acc+curr.clickCount, 0) * x.epc
            return x
          }), 'earnings', this.sortOrder)
        default:
          return this.Charts
      }
    }
  },
  methods: {
    ...mapActions([
        'GetCharts',
        'DeleteChart',
        'GetTrackingIds',
        'UpdateChart',
        'CreateChart',
        'CopyChart']),
    ...mapMutations([
        'setCharts'
    ]),
    createNewChart() {
      try {
        this.CreateChart().then(x => {
          this.$router.push('/chart/' + x['uid'])
        })
      } catch (error) {
        console.log(error)
      }
    },
    onSortBy(header) {
      if (this.orderKey === header) {
        this.sortOrder = (this.sortOrder === 'asc') ? 'desc' : 'asc'
      } else {
        this.orderKey = header
        this.sortOrder = 'asc'
      }
    },
    // eslint-disable-next-line no-unused-vars
    onCopyChart(uid) {
      this.showInfoBanner = true
      this.infoBannerMsg = 'Generating Chart'
      this.CopyChart(uid).then(res => {
        this.showInfoBanner = false
        this.infoBannerMsg = ''
        this.$router.push('/chart/' + res['uid'])
      }).catch(e => {
        console.log("")
        this.errorBannerMsg = e.response.data.message
        this.showErrorBanner = true
      })
    },
    onConfirmDeleteChart() {
      try {
        this.DeleteChart(this.chartToDelete)
        this.charts = this.GetCharts()
        this.chartToDelete = null
        this.isDeleteModalOpen = false
      } catch (error) {
        console.log(error)
      }
    },
    onDeleteChart(uid) {
      this.isDeleteModalOpen = true
      this.chartToDelete = uid
    },
  },
  created() {
    this.GetCharts()
    this.GetTrackingIds()
  }
}
</script>