<template>
  <BtnBase
      :size="size"
      :classes="'border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500'">
    <slot></slot>
  </BtnBase>
</template>

<script>
import BtnBase from "@/components/Utils/Btn/Base";

export default {
  name: "BtnDanger",
  props: ['size'],
  components: {
    BtnBase
  },
}
</script>