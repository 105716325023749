<template>
  <div class="mt-6">


    <BtnSecondary
        :size="'md'"
        class="ml-4 md:ml-0"
        @click="saveTheme">
      Save Changes
    </BtnSecondary>

    <router-link :to="{ name: 'editChart', params: { chartId: $route.params.chartId }}">
      <BtnSecondary
          :size="'md'"
          class="ml-4">
        Done
      </BtnSecondary>
    </router-link>



    <div v-if="Chart" class="md:hidden">
      <div class="mt-4 ml-2">
        <span class="text-md mb-4 text-gray-600">Embed</span> <br>
        <span class="text-sm text-gray-600">
              To add this table to your web page, insert this into your HTML
            </span>
        <div class="mt-1">
          <input
              type="text"
              :value="embedLink"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                    border-gray-300 rounded-md"/>
        </div>
      </div>

      <div class="mt-4 ml-2 mb-6">
        <span class="text-md mb-4 text-gray-600">Screen Width</span> <br>
        <span class="text-sm text-gray-600">
              Some columns are hidden on smaller screens. Drag the slider to see how things look on different devices
            </span>
        <div>
          <input type="range" min="250" max="1500" class="slider" v-model="previewSize">
        </div>
      </div>

      <span class="text-md mb-4 ml-2 text-gray-600">Appearance</span> <br>
    <nav
        class="md:hidden mt-5 flex-1 px-2 space-y-1 cursor-pointer mb-6">
        <div
            v-for="cssKey in cssKeys"
            :key="cssKey.value">
          <div
              @click="selectedStyle = cssKey.value"
              class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">
            <div
                :style="{'background': Chart[cssKey.value]}"
                class="border-gray-300 border-md text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6">
            </div>
            {{ cssKey.display }}
          </div>
          <ColorPicker
              v-if="selectedStyle && selectedStyle === cssKey.value"
              theme="light"
              :color="Chart[cssKey.value]"
              :sucker-hide="true"
              :sucker-canvas="suckerCanvas"
              :sucker-area="suckerArea"
              @changeColor="changeColor"/>
        </div>
    </nav>
      <BtnPrimary
          :size="'md'"
          class="mr-2 ml-4 mb-6"
          @click="isNewThemeModalOpen = true">
        Save Theme
      </BtnPrimary>
    </div>
    <div v-if="Chart" class="h-screen flex overflow-hidden bg-gray-100">

      <!-- Static sidebar for desktop -->
      <div class="hidden md:flex md:flex-shrink-0">
        <div class="flex flex-col w-64">
          <div class="mt-4">
            <span class="text-md mb-4 text-gray-600">Embed</span> <br>
            <span class="text-sm text-gray-600">
              To add this table to your web page, insert this into your HTML
            </span>
              <div class="mt-1">
                <input
                    type="text"
                    :value="embedLink"
                    class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm
                    border-gray-300 rounded-md"/>
              </div>
          </div>
          <div class="mt-4">
            <span class="text-md mb-4 text-gray-600">Screen Width</span> <br>
            <span class="text-sm text-gray-600">
              Some columns are hidden on smaller screens. Drag the slider to see how things look on different devices
            </span>
            <div>
              <input type="range" min="250" max="1500" class="slider" v-model="previewSize">
            </div>
          </div>
          <!-- Sidebar component, swap this element with another sidebar if you like -->
          <div class="flex flex-col h-0 flex-1 border-r border-gray-200">
            <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <span class="text-md mb-4 text-gray-600">Appearance</span> <br>
              <nav class="mt-5 flex-1 px-2 space-y-1 cursor-pointer">
                <div
                    v-for="cssKey in cssKeys"
                    :key="cssKey.value">
                  <div
                      @click="selectedStyle = cssKey.value"
                      class="text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md">

                    <div
                        :style="{'background': Chart[cssKey.value]}"
                        class="text-gray-400 group-hover:text-gray-500 mr-3 flex-shrink-0 h-6 w-6">
                    </div>
                    {{ cssKey.display }}
                  </div>
                  <ColorPicker
                      v-if="selectedStyle && selectedStyle === cssKey.value"
                      theme="light"
                      :color="Chart[cssKey.value]"
                      :sucker-hide="true"
                      :sucker-canvas="suckerCanvas"
                      :sucker-area="suckerArea"
                      @changeColor="changeColor"/>
                </div>
              </nav>
              <BtnPrimary
                  :size="'md'"
                  class="mr-2"
                  @click="isNewThemeModalOpen = true">
                Save Theme
              </BtnPrimary>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col w-0 flex-1 overflow-hidden">
        <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
          <div class="py-6">
            <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              <!-- Replace with your content -->
              <div class="py-4">
                <iframe
                    class="iframeClass"
                    ref="root"
                    :src="getPreviewUrl($route.params.chartId)"
                    :width="previewSize"
                    height="2000px">
                </iframe>
              </div>
              <!-- /End replace -->
            </div>
          </div>
        </main>
      </div>
    </div>

    <ConfirmModal
        :open="isNewThemeModalOpen"
        @cancelModal="isNewThemeModalOpen = false"
        @confirmModal="saveNewTheme"
        :cancel-btn-text="'Cancel'"
        :confirm-btn-text="'Save'">
      <template v-slot:header>
        Save Theme
      </template>
      <template v-slot:body>
        <div class="mt-1">
          <input
              type="text"
              placeholder="Theme Name"
              v-model="newThemeName"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300
              rounded-md"/>
        </div>
      </template>
    </ConfirmModal>


  </div>

</template>


<script>
import {ColorPicker} from 'vue-color-kit'
import ConfirmModal from "@/components/Utils/ConfirmModal";
import 'vue-color-kit/dist/vue-color-kit.css'
import {mapActions, mapGetters} from "vuex";
import BtnPrimary from "@/components/Utils/Btn/Primary";
import BtnSecondary from "@/components/Utils/Btn/Secondary";

export default {
  name: "PreviewChart",
  components: {
    ConfirmModal,
    ColorPicker,
    BtnPrimary,
    BtnSecondary
  },
  data() {
    return {
      isNewThemeModalOpen: false,
      newThemeName: 'New Theme',
      themeName: null,
      previewSize: '100%',
      suckerCanvas: null,
      suckerArea: [],
      isSucking: false,
      cssKeys: [
        {
          "value": "colorHeaderText",
          "display": "Header Text"
        },
        {
          "value": "colorHeaderBackground",
          "display": "Header Background"
        },
        {
          "value": "colorRowText",
          "display": "Row Text"
        },
        {
          "value": "colorOddRowBackground",
          "display": "Odd Row Background"
        },
        {
          "value": "colorEvenRowBackground",
          "display": "Even Row Background"
        },
        {
          "value": "colorTopPickBackground",
          "display": "Top Pick Background"
        },
        {
          "value": "colorButtonText",
          "display": "Button Text"
        },
        {
          "value": "colorButtonBackground",
          "display": "Button Background"
        }
      ],
      selectedStyle: null
    }
  },
  computed: {
    ...mapGetters({
      Chart: 'StateSelectedChart'
    }),
    embedLink() {
      // eslint-disable-next-line no-useless-escape
      return `<script src="https://api.tablelabs.com/t/${this.$route.params.chartId}.js" defer><\/script>`
    }
  },
  methods: {
    ...mapActions([
      'GetChart',
      'UpdateChart',
        'CreateNewTheme'
    ]),
    saveNewTheme(){
      if(!this.newThemeName)
        return

      let data = {
        'name': this.newThemeName,
        'theme': 0
      }
      this.cssKeys.forEach(x => {
        data[x['value']] = this.Chart[x['value']]
      })

      this.CreateNewTheme(data)
      this.isNewThemeModalOpen = false

    },
    getPreviewUrl(chartId) {

      return `${process.env.VUE_APP_BASE_URL}charts/${chartId}/preview`
    }, changeColor(color) {
      let els = document.getElementsByTagName("iframe")[0]

      let newStyle = `
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_header {
              color: ${this.Chart['colorHeaderText']} !important;
              background-color: ${this.Chart['colorHeaderBackground']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_row_text,
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_row_text:visited {
              color: ${this.Chart['colorRowText']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_odd_row_background {
              background-color: ${this.Chart['colorOddRowBackground']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_even_row_background {
              background-color: ${this.Chart['colorEvenRowBackground']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_top_pick_background {
              background-color: ${this.Chart['colorTopPickBackground']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_top_pick_border {
              box-shadow: 0px 0px 0px 7px #f6fafa, 0px 0px 15px 5px #f6fafa !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_button_text,
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_button_text:visited {
              color: ${this.Chart['colorButtonText']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_button_background {
              background-color: ${this.Chart['colorButtonBackground']} !important;
              border-color: ${this.Chart['colorButtonBackground']} !important;
            }
            [data-chart-uid="${this.$route.params.chartId}"] .tablelabs--color_top_pick_corner {
              border-color: ${this.Chart['colorButtonBackground']} transparent transparent transparent !important;
            }`
      els.contentWindow.postMessage(newStyle, "*")

      this.Chart[this.selectedStyle] = color.hex
    },
    saveTheme(chartId, chart) {
      let data = {}
      this.cssKeys.forEach(x => {
        data[x['value']] = chart[x['value']]
      })

      this.UpdateChart({chartId, data})
    },
  },
  created() {
    this.GetChart(this.$route.params.chartId)
  },
}
</script>
<style>
.hu-color-picker .color-type,
.hu-color-picker .color-show,
.hu-color-picker .colors,
.hu-color-picker .color-alpha {
  display: none;
}

</style>