import { createRouter, createWebHistory } from 'vue-router'
import LoginComponent from '@/components/LoginComponent'
import RegisterComponent from "@/components/RegisterComponent";
import ForgotPasswordComponent from "@/components/ForgotPasswordComponent";
import EditChart from "@/components/EditChart";
import Dashboard from '@/components/Dashboard/Dashboard'
import Settings from "@/components/Settings";
import Billing from "@/components/Billing";
import ProductSearch from "@/components/ProductSearch";
import PreviewChart from "@/PreviewChart";
import store from "@/store/index"
import VerifyComponent from "@/components/VerifyComponent";
import axios from "axios";

const routes = [
    {
        path: '/',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            title: 'Dashboard',
            requiresAuth: true
        }
    }, {
        path: '/chart/:chartId',
        name: 'editChart',
        component: EditChart,
        meta: {
            title: 'Edit Chart',
            requiresAuth: true
        }
    }, {
        path: '/chart/:chartId/search',
        name: 'productSearch',
        component: ProductSearch,
        meta: {
            title: 'Product Search',
            requiresAuth: true
        }
    },{
        path: '/chart/:chartId/preview',
        name: 'previewChart',
        component: PreviewChart,
        meta: {
            title: 'Preview Chart',
            requiresAuth: true
        }
    },{
        path: '/login',
        component: LoginComponent,
        name: 'login',
        meta: {
            guest: true
        }
    },{
        path: '/register',
        component: RegisterComponent,
        name: 'register',
        meta: {
            guest: true
        }
    }, {
        path: '/forgot-password',
        component: ForgotPasswordComponent,
        name: 'forgotPassword',
        meta: {
            guest: true
        }
    }, {
        path: '/settings',
        component: Settings,
        name: 'settings',
        meta: {
            title: 'Settings',
            requiresAuth: true
        }
    }, {
        path: '/billing',
        component: Billing,
        name: 'billing',
        meta: {
            title: 'Billing',
            requiresAuth: true
        }
    }, {
        path: '/verify/:verifyId',
        component: VerifyComponent,
        name: 'verify',
        meta: {
            guest: true
        }

    }
    /**{
        path: "/:catchAll(.*)",
        component: NotFound,
    },**/
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
})


router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated
    // Not logged into a guarded route?
    if ( to.meta.requiresAuth && !isAuthenticated ) next({ name: 'login' })
    else if ( (to.name === 'login' || to.name === 'register') && isAuthenticated ) next({ name: 'dashboard' })
    else next()
})


axios.interceptors.response.use(
    response => response,
    error => {
        if ((error.response.status === 401)) {
            store.dispatch('LogOut')
            return router.push('/login')
        }
        return Promise.reject(error)
})

export default router
