<template>
  <div>
    <span
        v-if="(!isTitleEditorActive) && Chart"
        @click="isTitleEditorActive = true"
        class="text-xl ml-2 md:ml-0 block">
      {{ Chart.title }}
    </span>
    <div
        v-if="isTitleEditorActive"
        class="mt-1 ml-2 md:ml-0">
      <input
          @blur="updateTitle(Chart.title)"
          v-model="Chart.title"
          type="text"
          name="chartTitle"
          id="chartTitle"
          class="max-w-xs shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300
            rounded-md"/>
    </div>



    <span
        class="text-sm mt-2 text-gray-600 ml-2 md:ml-0 block">
      Amazon Tracking ID: <span class="text-indigo-600 cursor-pointer" v-if="(!isTrackingIdEditorActive) && Chart"
                                @click="isTrackingIdEditorActive = true"> {{ Chart.trackingId }}</span>
      <TrackingIdPicker
          @updateTrackingId="onUpdateTrackingId"
          :tracking-ids="TrackingIds"
          :chart="Chart"
          v-if="(isTrackingIdEditorActive) && Chart"
          class="mt-2 ml-2 md:ml-0 inline-block">
    </TrackingIdPicker>
    </span>
    <span
        v-if="Chart"
        class="block text-sm mt-2 ml-2 md:ml-0 text-gray-600">
      Views: {{ Chart.viewCount }}, Clicks: {{ clicks }}, CTR: {{ ctr }}
    </span>
    <div class="mb-6 mt-6">

      <span class="relative z-0 md:inline-flex shadow-sm rounded-md hidden float-right">
          <button type="button"
                  @click="isEmbedModalOpen = true"
                  class="-ml-px relative inline-flex items-center px-4 py-2  border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            Embed
          </button>
          <router-link :to="{ name: 'previewChart', params: { chartId: $route.params.chartId }}">
            <button type="button"
                    class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
              Preview
            </button>
          </router-link>
          <button type="button"
                  @click="done(Chart.title)"
                  class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            Done
          </button>
        </span>
      <ThemePicker
          class="float-right hidden md:block"
          :chartId="$route.params.chartId">
      </ThemePicker>

      <div>
        <div class="mt-1 flex rounded-md">
          <div class="relative w-62 focus-within:z-10">
            <select
                v-model="selectedColumnType"
                @change="updateSelectedColumnType"
                class="ml-2 sm:ml-0 focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300">
              <option
                  v-for="columnType in columnTypes"
                  :key="columnType.value"
                  :value="columnType.value">{{ columnType.title }}
              </option>
            </select>
          </div>
          <button
              @click="addColumn(selectedColumnType)"
              class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm
            font-medium rounded-r-md focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
            text-white bg-indigo-600 hover:bg-indigo-700">
            Add Column
          </button>
        </div>
      </div>

      <div class="md:hidden">
        <ThemePicker
            class="ml-2 mt-2"
            :chartId="$route.params.chartId">
        </ThemePicker>
        <BtnSecondary
            @click="isEmbedModalOpen = true"
            class="ml-2 mt-2 mb-2"
            :size="'md'">
          Embed
        </BtnSecondary>

        <router-link :to="{ name: 'previewChart', params: { chartId: $route.params.chartId }}">
          <BtnSecondary
              class="ml-2 mt-2 mb-2"
              :size="'md'">
            Preview
          </BtnSecondary>
        </router-link>

        <BtnSecondary
            @click="done(Chart.title)"
            class="ml-2 mt-2 mb-2"
            :size="'md'">
          Done
        </BtnSecondary>
      </div>
    </div>

    <div class="ml-2 md:ml-0" v-if="selectedColumn['id']">
      <div class="relative m-4 mb-2">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300"></div>
        </div>
      </div>
      <div>
        <form @submit.prevent="saveNewColumnName(selectedColumn)">
          <label for="columnName" class="block text-sm font-medium text-gray-700">Column Name</label>
          <div class="mt-1 max-w-md flex rounded-md">
            <div class="relative flex items-stretch flex-grow focus-within:z-10">
              <input
                  :value="selectedColumn.name"
                  type="text"
                  ref="columnName"
                  id="columnName"
                  class="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md sm:text-sm
                  border-gray-300"/>
            </div>
            <input
                value="Save"
                type="submit"
                class="-ml-px mr-6 relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm
                font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1
                focus:ring-indigo-500 focus:border-indigo-500">
            <BtnDanger
                :size="'md'"
                @click="this.isDeleteColumnModalOpen = true">
              Delete Column
            </BtnDanger>
          </div>
        </form>
      </div>

      <SizeSelection
          class="max-w-xs mb-6 mt-4"
          :selected-column="selectedColumn"
          @updateColumnSize="onUpdateColumnSize">
      </SizeSelection>
    </div>


    <div class="row" v-if="productList">
      <div
          class="col-8 overflow-x-scroll pb-24">
        <table
            ref="tableBoundingBox"
            class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">

          <draggable
              v-model="Headers"
              tag="tr"
              :item-key="key => key"
              :move="onHeaderMove"
              @change="onEnd"
              handle=".handle">
            <template #item="{ element: header }">
              <th
                  scope="col"
                  :class="{'bg-gray-300': header['id'] === selectedColumn['id']}"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">
                <div class="relative flex space-x-3">
                  <div class="pt-1.5"
                       v-if="!((header['columnType'] === 'TopPick') || (header['columnType'] === 'Clicks'))">
                    <SelectorIcon class="handle cursor-move block h-4 w-4 transform rotate-90"></SelectorIcon>
                  </div>
                  <div class="flex-1 pt-1.5 flex justify-between uppercase">
                    {{ header['name'] }}
                    <CogIcon
                        v-if="!((header['columnType'] === 'TopPick') || (header['columnType'] === 'Clicks'))"
                        v-on:click="selectedColumn = header" class="inline-block cursor-pointer h-4 w-4">
                    </CogIcon>
                  </div>
                </div>
              </th>
            </template>
          </draggable>

          </thead>

          <draggable
              handle=".handle2"
              v-model="productList"
              :item-key="key => key.uid"
              tag="tbody">
            <template #item="{element, index}">
              <tr
                  :key="element.uid"
                  :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
                <td
                    scope="row"
                    v-for="(header, headerIdx) in Headers"
                    :key="header.id"
                    @click="toggleEditor(element, header)"
                    class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 relative">
                  <span v-if="header['columnType']==='Clicks'">
                    <SelectorIcon class="handle2 cursor-move inline-block h-4 w-4"></SelectorIcon>
                    {{ element['clickCount'] }}
                  </span>
                  <span v-else-if="header['columnType']==='TopPick'">
                    <input
                        type="radio"
                        @change="updateTopPick(element['uid'])"
                        :checked="element['uid'] === Chart['topPick']"
                        class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300">
                  </span>
                  <img v-else-if="header['columnType']==='Image'" :src="element['image']" alt="">
                  <span v-else-if="header['columnType']==='Price'">{{ element['price'] }}</span>
                  <select
                      v-else-if="header['columnType']==='Rating'"
                      :value="element['rating']"
                      @change="updateItemField(element, 'rating', $event.target.value)"
                      class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md">
                    <option v-for="index in 10" :key="index">{{ index * 0.5 }}</option>
                  </select>
                  <span v-else-if="header['columnType']==='Rating'">{{ element['rating'] }}</span>
                  <div
                      class="max-w-xs cursor-pointer"
                      v-else-if="header['columnType']==='BuyButton'">
                    <span
                        class="block w-full sm:text-sm whitespace-pre overflow-hidden">
                      {{ element['buttonTitle'] }}
                    </span>
                    <div
                        v-if="(selectedElement.uid === element.uid) && (selectedHeader.id === header['id'])"
                        class="absolute rounded bg-indigo-600 w-80 p-1 top-4 z-10">
                      <input type="text"
                             class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500
                             sm:text-sm border-gray-300 rounded-md"
                             v-model="activeDefaultCellValue">

                      <div class="w-full text-right cursor-pointer mt-1 text-indigo-600">
                        <XIcon
                            class="w-7 h-7 bg-indigo-100 rounded float-right"
                            @click.stop="cancelCustomCellEditor">
                        </XIcon>
                        <CheckIcon
                            class="w-7 h-7 mr-2 bg-indigo-100 rounded float-right"
                            @click.stop="updateItemField(element, 'buttonTitle', activeDefaultCellValue)">
                        </CheckIcon>
                      </div>
                    </div>
                  </div>
                  <div
                      class="max-w-xs cursor-pointer"
                      v-else-if="header['columnType']==='Title'">
                    <span
                        class="block max-w-sm sm:text-sm whitespace-pre overflow-hidden">
                      {{ element['title'] }}
                    </span>
                    <div
                        v-if="(selectedElement.uid === element.uid) && (selectedHeader.id === header['id'])"
                        class="absolute rounded bg-indigo-600 w-80 p-1 top-4 z-10">
                      <textarea
                          class="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500
                          sm:text-sm border-gray-300 rounded-md"
                          v-model="activeDefaultCellValue"
                          cols="30"
                          rows="2">
                      </textarea>

                      <div class="w-full text-right cursor-pointer mt-1 text-indigo-600">
                        <XIcon
                            class="w-7 h-7 bg-indigo-100 rounded float-right"
                            @click.stop="cancelCustomCellEditor">
                        </XIcon>
                        <CheckIcon
                            class="w-7 h-7 mr-2 bg-indigo-100 rounded float-right"
                            @click.stop="updateItemField(element, 'title', activeDefaultCellValue)"
                        >
                        </CheckIcon>
                      </div>


                    </div>
                  </div>
                  <div
                      class="max-w-xs cursor-pointer"
                      v-else-if="header['columnType']==='Custom'">
                    <span
                        class="block w-full sm:text-sm whitespace-pre overflow-hidden">
                      {{ getCellByColumnId(element, header['id']) }}
                    </span>
                    <div

                        v-if="(selectedElement.uid === element.uid) && (selectedHeader.id === header['id'])"
                        class="absolute rounded bg-indigo-600 w-80 p-1 top-4 z-10">
                      <textarea
                          class="max-w-lg block w-full focus:ring-indigo-500 focus:border-indigo-500
                          sm:text-sm border-gray-300 rounded-md"
                          v-model="activeCellValue"
                          cols="30"
                          rows="5"></textarea>
                      <div class="w-full text-right cursor-pointer mt-1 text-indigo-600">
                        <XIcon
                            class="w-7 h-7 bg-indigo-100 rounded float-right"
                            @click.stop="cancelCustomCellEditor">
                        </XIcon>
                        <CheckIcon
                            class="w-7 h-7 mr-2 bg-indigo-100 rounded float-right"
                            @click.stop="updateCustomCell(element, header['id'], activeCellValue)">
                        </CheckIcon>
                      </div>

                    </div>
                  </div>
                  <span
                      class="float-right"
                      v-if="headerIdx === Headers.length - 1">
                    <TrashIcon
                        class="w-4 h-4"
                        @click="deleteItem(element['uid'])">
                    </TrashIcon>
                  </span>
                </td>
              </tr>
            </template>
          </draggable>

        </table>
      </div>
    </div>

    <div class="mb-16">
      <router-link
          :to="{ name: 'productSearch', params: { chartId: $route.params.chartId }}"
          class="mt-6 float-right">
        <BtnPrimary :size="'md'">Add New Products</BtnPrimary>
      </router-link>

    </div>

    <DangerModal
        :data="itemToDelete"
        :open="isDeleteItemModalOpen"
        @cancelModal="isDeleteItemModalOpen = false"
        @confirmModal="onConfirmDeleteItem">
      <template v-slot:header>
        Do you want to delete this item
      </template>
      <template v-slot:body>
        Are you sure you want to delete this item
      </template>
    </DangerModal>

    <DangerModal
        :open="isDeleteColumnModalOpen"
        :data="selectedColumn"
        @cancelModal="isDeleteColumnModalOpen = false"
        @confirmModal="onConfirmDeleteColumn">
      <template v-slot:header>
        Do you want to delete this column
      </template>
      <template v-slot:body>
        Are you sure you want to delete this column
      </template>
    </DangerModal>

    <DangerModal
        :hide-cancel="true"
        :open="isInvalidTrackingIdModalOpen"
        @confirmModal="isInvalidTrackingIdModalOpen = false">
      <template v-slot:header>
        Invalid Tracking ID (Partner Tag)
      </template>
      <template v-slot:body>
        The selected Amazon Tracking ID (Partner Tag) is invalid with your current Amazon Product Advertising API
        access key. Prices can not be updated and will be hidden. Please check the Tracking ID and access key.
      </template>
    </DangerModal>

    <ConfirmModal
        :open="isEmbedModalOpen"
        @cancelModal="isEmbedModalOpen = false"
        @confirmModal="isEmbedModalOpen = false"
        :confirm-btn-text="'Ok'">
      <template v-slot:header>
        Embedded Link
      </template>
      <template v-slot:body>
        {{ embeddedLink }}
      </template>
    </ConfirmModal>

    <InfoBanner
        :hide-dismiss="true"
        :show="showInfoBanner">
      {{infoBannerMsg}}
    </InfoBanner>


  </div>
</template>

<script>
/* eslint-disable */

import {mapActions, mapGetters, mapMutations} from 'vuex';
import draggable from 'vuedraggable';
import ThemePicker from "@/components/EditChart/ThemePicker";
import SizeSelection from "@/components/EditChart/SizeSelection";
import {SelectorIcon, CogIcon, TrashIcon, CheckIcon, XIcon} from '@heroicons/vue/solid';
import BtnDanger from "@/components/Utils/Btn/Danger";
import BtnPrimary from "@/components/Utils/Btn/Primary";
import BtnSecondary from "@/components/Utils/Btn/Secondary"
import DangerModal from "@/components/Utils/DangerModal";
import ConfirmModal from "@/components/Utils/ConfirmModal";
import TrackingIdPicker from "@/components/EditChart/TrackingIdPicker";
import InfoBanner from "@/components/Utils/Banner/Info"


export default {
  name: "EditChart",
  components: {
    draggable,
    ThemePicker,
    TrackingIdPicker,
    CogIcon,
    TrashIcon,
    SelectorIcon,
    CheckIcon,
    XIcon,
    SizeSelection,
    BtnDanger,
    BtnPrimary,
    BtnSecondary,
    DangerModal,
    ConfirmModal,
    InfoBanner,
  },
  data() {
    return {
      modalState: false,
      dragging: false,
      selectedColumn: {id: ''},
      selectedHeader: '',
      selectedElement: '',
      itemToDelete: null,
      isScreenSizeModalOpen: false,
      isDeleteItemModalOpen: false,
      isDeleteColumnModalOpen: false,
      isEmbedModalOpen: false,
      selectedColumnType: null,
      newEditorValue: '',
      isTitleEditorActive: false,
      isTrackingIdEditorActive: false,
      showInfoBanner: false,
      infoBannerMsg: '',
      isInvalidTrackingIdModalOpen: false
    };
  },
  computed: {
    ...mapGetters({
      Chart: 'StateSelectedChart',
      Headers: 'StateHeaderSelectedChart',
      Themes: 'StateThemes',
      TrackingIds: 'StateTrackingIds'
    }),
    clicks() {
      if (this.Chart)
        return this.Chart.items.reduce((acc, curr) => acc + curr.clickCount, 0)
      else
        return 0
    },
    ctr() {
      if (!this.Chart) {
        return ''
      }
      if (this.Chart.viewCount === 0) {
        return 0 + "%"
      } else {
        let chartClicks = this.Chart.items.reduce((acc, curr) => acc + curr.clickCount, 0)

        if (chartClicks === 0) {
          return 0 + '%'
        }
        return (chartClicks / this.Chart.viewCount).toFixed(2) + "%"
      }
    },
    columnTypes() {
      let allDefaultColumnTypes = [
        {title: 'Image Column', value: 'Image'},
        {title: 'Title Column', value: 'Title'},
        {title: 'Rating Column', value: 'Rating'},
        {title: 'Price Column', value: 'Price'},
        {title: 'Buy Button Column', value: 'BuyButton'}
      ]

      if (this.Chart) {
        let filteredColumns = allDefaultColumnTypes
            .filter(x => !(this.Chart.customColumns.find(elem => elem.columnType === x.value)))
        filteredColumns.push({title: 'Custom Column', value: 'Custom'})

        if (!this.selectedColumnType) {
          this.selectedColumnType = filteredColumns[0].value
        }
        return filteredColumns
      } else {
        return []
      }
    },
    embeddedLink() {
      if (this.Chart) {
        return `<script src="https://api.tablelabs.com/t/${this.Chart.uid}.js" defer><\/script>`
      }
      return ""
    },
    productList: {
      get: function () {
        if (this.Chart) {
          return [...this.Chart['items']].sort((x, y) => x.position - y.position)
        } else {
          return []
        }
      },
      set: function (newValue) {
        newValue.map((x, index) => {
          this.UpdateItem({
            'uid': x['uid'],
            'item': x,
            'request': {
              'position': index + 1
            }
          })
          x.position = index + 1
          return x
        })

      }
    },
    activeCellValue: {
      get: function () {
        if (this.newEditorValue) {
          return this.newEditorValue
        } else if (this.selectedElement && this.selectedHeader) {
          return this.getCellByColumnId(this.selectedElement, this.selectedHeader.id)
        }
        return ''
      },
      set: function (newValue) {
        this.newEditorValue = newValue
      }
    },
    activeDefaultCellValue: {
      get: function () {
        if (this.newEditorValue) {
          return this.newEditorValue
        } else if (this.selectedElement && this.selectedHeader) {
          if (this.selectedHeader.columnType === 'Title')
            return this.selectedElement['title']
          else if (this.selectedHeader.columnType === 'BuyButton')
            return this.selectedElement['buttonTitle']
        }
        return ''
      },
      set: function (newValue) {
        this.newEditorValue = newValue
      }
    }
  },
  methods: {
    ...mapActions([
      'GetChart',
      'GetThemes',
      'CreateChart',
      'UpdateColumnOrder',
      'UpdateTopPick',
      'UpdateCell',
      'UpdateItem',
      'UpdateColumn',
      'AddNewColumn',
      'DeleteColumn',
      'UpdateItem',
      'DeleteItem',
      'GetTrackingIds',
      'UpdateChart'
    ]),
    ...mapMutations([
      'updateChartHeaderOrder',
    ]),
    onUpdateTrackingId(data) {
      this.showInfoBanner = true
      this.infoBannerMsg = "Saving"
      this.UpdateChart(
          {
            chartId: data.chartId,
            data: {trackingId: data.trackingId}}
      ).then(res => {
        this.isTrackingIdEditorActive = false
        this.showInfoBanner = false
        this.infoBannerMsg = ''
        this.isInvalidTrackingIdModalOpen = !this.Chart.trackingIdValid
      })

    },
    done(title) {
      this.updateTitle(title)
      this.$router.replace({name: 'dashboard'})
    },
    updateTitle(title) {
      this.isTitleEditorActive = false
      this.UpdateChart({chartId: this.$route.params.chartId, data: {title}})
    },
    toggleEditor(element, header) {
      this.newEditorValue = ''
      this.selectedElement = element
      this.selectedHeader = header
    },
    onUpdateColumnSize({column, newColumnSize}) {
      this.UpdateColumn({
        'uid': column.id,
        'request': {
          columnSize: newColumnSize,
          name: column['name']
        }
      })
    },
    getCellByColumnId(item, columnId) {
      let customCells = item['customCells']
      return customCells.filter(x => x['customColumn'] === columnId)[0].content
    },
    updateSelectedColumnType(e) {
      this.selectedColumnType = e.target.value
    },
    addColumn(columnType) {
      this.AddNewColumn(
          {
            "chart": this.$route.params.chartId,
            "name": columnType,
            "columnType": columnType
            // eslint-disable-next-line no-unused-vars
          }).then(x => {
        this.GetChart(this.$route.params.chartId)
        this.selectedColumnType = 'Custom'
      })
    },
    updateItemField(item, field, value) {
      item[field] = value
      let data = {
        'uid': item['uid'],
        'item': item,
        'request': {}
      }

      data['request'][field] = value

      this.UpdateItem(data)
      this.closeCellEditor()
    },
    updateCustomCell(item, columnId, value) {
      let customCells = item['customCells']
      let customCell = customCells.filter(x => x['customColumn'] === columnId)[0]
      customCell.content = value
      this.UpdateCell({
        'uid': customCell['id'],
        'request': {
          'content': customCell['content']
        }
      })

      this.closeCellEditor()
    },
    closeCellEditor() {
      this.selectedHeader = ''
      this.selectedElement = ''
      this.newEditorValue = ''
    },
    cancelCustomCellEditor() {
      this.closeCellEditor()
    },
    updateTopPick(itemUid) {
      this.UpdateTopPick({'uid': this.$route.params.chartId, 'topPick': itemUid})
    },
    onHeaderMove(e) {
      if (e.draggedContext.element.columnType === 'Clicks' || e.draggedContext.element.columnType === 'TopPick')
        return
    },
    onEnd(e) {
      let moved = e['moved']
      let oldIdx = moved['oldIndex'] - 2
      let newIdx = moved['newIndex'] - 2

      if (oldIdx < 0 || newIdx < 0)
        return
      let a = this.Chart.columnOrder[oldIdx]
      let b = this.Chart.columnOrder[newIdx]
      let columnOrder = this.Chart.columnOrder

      columnOrder[oldIdx] = b
      columnOrder[newIdx] = a

      this.UpdateColumnOrder({'uid': this.$route.params.chartId, 'columnOrder': columnOrder})
    },
    onConfirmDeleteColumn(data) {
      // eslint-disable-next-line no-unused-vars
      this.DeleteColumn(data.id).then(x => {
        this.GetChart(this.$route.params.chartId)
        this.selectedColumn = {id: ''}
        this.isDeleteColumnModalOpen = false
      })
    },
    updateColumnStyling(column, newColumnSize) {
      this.UpdateColumn({
        'uid': column.id,
        'request': {
          columnSize: newColumnSize,
          name: column['name']
        }
      })
    },
    saveNewColumnName(column) {
      let newColumnName = this.$refs.columnName.value

      this.UpdateColumn({
        'uid': column.id,
        'request': {
          columnSize: column.columnSize,
          name: newColumnName
        }
      })
    },
    deleteItem(itemId) {
      this.itemToDelete = itemId
      this.isDeleteItemModalOpen = true
    },
    onConfirmDeleteItem(itemId) {
      // eslint-disable-next-line no-unused-vars
      this.DeleteItem(itemId).then(x => {
        this.isDeleteItemModalOpen = false
        this.itemToDelete = null
      })
    }
  },
  created() {
    this.GetChart(this.$route.params.chartId)
        .then(res => this.isInvalidTrackingIdModalOpen = !res.data.trackingIdValid)
    this.GetThemes()
    this.GetTrackingIds()
  },
}
</script>