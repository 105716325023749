<template>
  <button
      type="button"
      class="inline-flex items-center border font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
      :class="[{
          'px-2.5 py-1.5 text-xs rounded': size === 'xs',
          'px-3 py-2  text-sm leading-4 rounded-md': size === 'sm',
          'px-4 py-2 text-sm rounded-md': size === 'md',
          'px-4 py-2 text-base rounded-md': size === 'lg',
          'text px-6 py-3 text-base rounded-md': size === 'xl',
      }, classes]">
    <slot></slot>
  </button>


  
</template>

<script>
export default {
  name: "BtnBase",
  props: ['size', 'classes']
}
</script>

<style scoped>

</style>