<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr>
              <th
                  @click="sortByHeader(title)"
                  v-for="(title, titleIdx) in table_head"
                  :key="titleIdx"
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                {{ title }}
                <SortAscendingIcon
                    class="w-4 h-4 inline-block"
                    v-if="(sortOrder === 'asc') && (title === orderKey)">
                </SortAscendingIcon>
                <SortDescendingIcon
                    class="w-4 h-4 inline-block"
                    v-if="(sortOrder === 'desc') && (title === orderKey)">
                </SortDescendingIcon>
              </th>

            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <ChartRow
                  v-for="(chart, chartIdx) in charts"
                  :key="chart.uid"
                  :chart="chart"
                  :class="chartIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                  @copyChart="copyChart"
                  @deleteChart="deleteChart">
              </ChartRow>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartRow from "@/components/Dashboard/ChartRow";
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/vue/solid'

const table_head = [
    'Name', 'Views', 'Clicks', 'CTR', 'EPC', 'EARNINGS', 'TRACKING ID', 'UPDATED', 'Actions'
]

export default {
  name: "Chart",
  components: {
    ChartRow,
    SortAscendingIcon,
    SortDescendingIcon
  },
  props: [
      'charts',
      'orderKey',
      'sortOrder'
  ],
  methods: {
    deleteChart(uid) {
      this.$emit("deleteChart", uid);
    },
    copyChart(uid) {
      this.$emit("copyChart", uid);
    },
    sortByHeader(header) {
      this.$emit("sortBy", header)
    }
  },
  setup() {
    return {
      table_head
    }
  }
}
</script>