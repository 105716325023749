<template>
  <div id="app" class="bg-gray-100">
    <div v-if="(this.$route.name !== 'login') && (this.$route.name !== 'register') && (this.$route.name !== 'verify')">

    <NavBar></NavBar>
    <Modal :is-open="isOpen" @confirmModal="confirmModal"></Modal>
    <div class="py-10">
      <header>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h1 class="text-3xl font-bold leading-tight text-gray-900">
            {{ $route.meta.title }}
          </h1>
        </div>
      </header>
      <main>
        <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
          <router-view class="view"></router-view>
        </div>
      </main>
    </div>
    </div>
    <div v-else>
      <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
        <router-view class="view"></router-view>
      </div>
    </div>

  </div>
</template>

<script>

import { ref } from 'vue'
import Modal from "@/components/Utils/Modal";
import NavBar from "@/components/NavBar";

const profile = ['Account', 'Billing', 'Help', 'Sign out']

export default {
  name: 'App',
  components: {
    NavBar,
    Modal,
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    confirmModal() {
      this.isOpen = false
    }
  },
  setup() {

    const open = ref(false)

    return {
      profile,
      open,
    }
  },

}
</script>

