<template>
  <tr>
    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
      {{ chart.title }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ chart.viewCount }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ countClicks(chart) }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ calculateCTR(chart) }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ chart.epc }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ calculateEarnings(chart)}}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      <span v-if="!chart.trackingIdValid"
            title="This Tracking ID is invalid">
        <ExclamationCircleIcon
            class="w-4 h-4 inline-block">
        </ExclamationCircleIcon>
      </span>
      {{ chart.trackingId }}
    </td>
    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {{ displayDate(chart.lastUpdated) }}
    </td>
    <td class="px-6 py-4">
      <span title="delete">
        <TrashIcon @click="deleteChart(chart.uid)"
                   class="inline-block cursor-pointer h-4 w-4 mr-2 text-red-500">

        </TrashIcon>
      </span>

      <router-link
          :to="{ name: 'editChart', params: { chartId: chart.uid }}">
        <span title="edit">
          <PencilIcon class="inline-block h-4 w-4 mr-2 text-gray-500">
          </PencilIcon>
        </span>
      </router-link>
      <span title="copy">
        <DocumentDuplicateIcon
            @click="copyChart(chart.uid)"
            class="inline-block h-4 w-4 text-gray-500 cursor-pointer">
        </DocumentDuplicateIcon>
      </span>
    </td>
  </tr>
</template>

<script>
import {PencilIcon, TrashIcon, ExclamationCircleIcon, DocumentDuplicateIcon} from '@heroicons/vue/solid'
import { getCurrencyForCountry } from "@/components/Utils/getCurrency"
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ChartRow",
  props: ['chart'],
  components: {
    PencilIcon,
    TrashIcon,
    ExclamationCircleIcon,
    DocumentDuplicateIcon
  },
  computed: {
    ...mapGetters({
      UserSettigs: 'StateUserSettings'
    })
  },
  methods: {
    ...mapActions(['GetUserSettings']),
    deleteChart(uid) {
      this.$emit("deleteChart", uid);
    },
    copyChart(uid) {
      this.$emit("copyChart", uid)
    },
    countClicks(chart) {
      return chart.items.reduce((acc, curr) => acc+curr.clickCount, 0)
    },
    calculateCTR(chart) {
      if (chart.viewCount === 0){
        return  0 + "%"
      } else {
        let chartClicks = chart.items.reduce((acc, curr) => acc+curr.clickCount, 0)

        if (chartClicks === 0) {
          return 0 + '%'
        }
        return(chartClicks/chart.viewCount).toFixed(2) + "%"
      }
    },
    displayDate(seconds) {
      const d = new Date(seconds);
      return d.toLocaleString('en-EN', {month: 'short'}) + " " + d.getDate()
    },
    calculateEarnings(chart) {
      let region = 'US'
      if (this.UserSettigs) {
        region = this.UserSettigs.amazonRegion
      }
      return getCurrencyForCountry(region) + ' ' + this.countClicks(chart) * chart.epc
    }
  },
  created() {
    this.GetUserSettings()
  }
}
</script>

<style scoped>

</style>