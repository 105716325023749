<template>
  <div>
    <div class="min-h-screen flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <WarningBanner
          :open="showError"
          @closeBanner="showError = false">
        {{ errorMessage }}
      </WarningBanner>
      <div class="max-w-md w-full space-y-8">
        <div>
          <img class="mx-auto h-12 w-auto" src="@/assets/logo.png" alt="Workflow"/>
          <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        <form @submit.prevent="submit" class="mt-8 space-y-6" action="#">
          <input type="hidden" name="remember" value="true"/>
          <div class="rounded-md shadow-sm -space-y-px">
            <div>
              <label for="email-address" class="sr-only">Email address</label>
              <input v-model="form.username" id="email-address" name="email" type="email" autocomplete="email"
                     required=""
                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                     placeholder="Email address"/>
            </div>
            <div>
              <label for="password" class="sr-only">Password</label>
              <input v-model="form.password" id="password" name="password" type="password"
                     autocomplete="current-password"
                     required=""
                     class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                     placeholder="Password"/>
            </div>
          </div>

          <div class="flex items-center justify-between">
            <div class="flex items-center">
              <input id="remember_me" name="remember_me" type="checkbox"
                     class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"/>
              <label for="remember_me" class="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>
          </div>

          <div>
            <button type="submit"
                    class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <LockClosedIcon class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true"/>
            </span>
              Sign in
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import WarningBanner from "@/components/Utils/Banner/Warning"
import {LockClosedIcon} from '@heroicons/vue/solid'

export default {
  name: "Login",
  components: {
    WarningBanner,
    LockClosedIcon
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false,
      errorMessage: ''
    };
  },
  computed: {
    ...mapGetters({User: "StateUser"}),
  },
  methods: {
    ...mapActions([
        "LogIn",
    ]),
    submit() {
      this.LogIn({
        username: this.form.username,
        password: this.form.password
        // eslint-disable-next-line no-unused-vars
      }).then(res => {
        this.showError = false
        this.$router.push("/");
      }).catch(e => {
        this.showError = true
        this.errorMessage = e.response.data['nonFieldErrors'][0]

        setTimeout(() => {
          this.showError = false
        }, 10000)

      });

    }
  },
}
</script>