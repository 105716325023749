<template>
  <div>
    <Menu as="div" class="relative inline-block text-left">
    <div class="cursor-pointer">
      <MenuButton v-if="activeTheme"
          class="inline-flex justify-center w-full rounded-md md:rounded-r-none border border-gray-300 shadow-sm px-4 py-2 bg-white
          text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2
          focus:ring-offset-gray-100 focus:ring-indigo-500">
        <div
            :class="activeTheme.class"
            :style="{ backgroundImage: getStylePreview(activeTheme) }"
            class="mr-3 h-5 w-5 rounded-xl"></div>
        {{activeTheme.name}}
        <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
    </div>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <MenuItems v-if="activeTheme"
          class="cursor-pointer z-50 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div class="py-1">
          <MenuItem v-for="theme in defaultThemes" :key="theme.id">
            <div
                :class="[theme.id === activeTheme.id ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']"
                @click="updateTheme(chartId, theme.id)">
              <div
                  :style="{ backgroundImage: getStylePreview(theme) }"
                  class="mr-3 h-5 w-5 rounded-xl"></div>
              {{theme.name}}
            </div>
          </MenuItem>
        </div>
        <div v-if="Themes" class="py-1">
          <MenuItem v-for="customTheme in Themes" :key="customTheme.id">
            <div
                :class="[customTheme.id === activeTheme.id ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm']"
                @click="updateCustomTheme(chartId, customTheme)">
              <div
                  :style="{ backgroundImage: getStylePreview(customTheme) }"
                  class="mr-3 h-5 w-5 rounded-xl"></div>
              {{customTheme.name}}
            </div>
          </MenuItem>
        </div>

      </MenuItems>
    </transition>
  </Menu>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Menu, MenuButton, MenuItem, MenuItems} from '@headlessui/vue'

let defaultThemes = [
  {
    'id': 0,
    'class': 'theme-icon-white-blue',
    "name": "white & blue",
    "colorHeaderText": "#66737e",
    "colorHeaderBackground": "#ffffff",
    "colorRowText": "#66737e",
    "colorOddRowBackground": "#ffffff",
    "colorEvenRowBackground": "#ffffff",
    "colorTopPickBackground": "#f6fafa",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#2296d4",
  },{
    'id': 1,
    'class': 'theme-icon-white-green',
    "name": "white & green",
    "colorHeaderText": "#3a444d",
    "colorHeaderBackground": "#ffffff",
    "colorRowText": "#66737e",
    "colorOddRowBackground": "#ffffff",
    "colorEvenRowBackground": "#f6fafa",
    "colorTopPickBackground": "#f6fafa",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#009800",
  },{
    'id': 2,
    'class': 'theme-icon-white-black',
    "name": "white & black",
    "colorHeaderText": "#ffffff",
    "colorHeaderBackground": "#000000",
    "colorRowText": "#66737e",
    "colorOddRowBackground": "#ffffff",
    "colorEvenRowBackground": "#f9f9f9",
    "colorTopPickBackground": "#f9f9f9",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#000000",

  },{
    'id': 3,
    'class': 'theme-icon-white-red',
    "name": "white & red",
    "colorHeaderText": "#ffffff",
    "colorHeaderBackground": "#646161",
    "colorRowText": "#66737e",
    "colorOddRowBackground": "#ffffff",
    "colorEvenRowBackground": "#f9f9f9",
    "colorTopPickBackground": "#f9f9f9",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#df1e1e",


  },{
    'id': 4,
    'class': 'theme-icon-black-orange',
    "name": "black & orange",
    "colorHeaderText": "#ffffff",
    "colorHeaderBackground": "#171717",
    "colorRowText": "#ffffff",
    "colorOddRowBackground": "#2c2c2c",
    "colorEvenRowBackground": "#3f3f3f",
    "colorTopPickBackground": "#3f3f3f",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#f8951b",

  },{
    'id': 5,
    'class': 'theme-icon-dark-blue',
    "name": "dark blue",
    "colorHeaderText": "#ffffff",
    "colorHeaderBackground": "#1b314a",
    "colorRowText": "#ffffff",
    "colorOddRowBackground": "#1b314a",
    "colorEvenRowBackground": "#213a5b",
    "colorTopPickBackground": "#213a5b",
    "colorButtonText": "#ffffff",
    "colorButtonBackground": "#00a2fd",

  },
]
import {
  ChevronDownIcon,
} from '@heroicons/vue/solid'

// Now take the other defaults and put them here

export default {
  name: "ThemePicker",
  props: ['chartId'],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
  },
  data() {
    return {
      defaultThemes
    }
  },
  methods: {
    ...mapActions([
      'UpdateChart'
    ]),
    updateTheme(chartId, themeId) {
      this.UpdateChart({chartId, data: {'theme': themeId}})
    },
    updateCustomTheme(chartId, theme) {
      let data = {
        "colorHeaderText": theme["colorHeaderText"],
        "colorHeaderBackground": theme["colorHeaderBackground"],
        "colorRowText": theme["colorRowText"],
        "colorOddRowBackground": theme["colorOddRowBackground"],
        "colorEvenRowBackground": theme["colorEvenRowBackground"],
        "colorTopPickBackground": theme["colorTopPickBackground"],
        "colorButtonText": theme["colorButtonText"],
        "colorButtonBackground": theme["colorButtonBackground"]
      }
      this.UpdateChart({chartId, data})
    },
    getStylePreview(theme) {
      return `linear-gradient(to bottom, ${theme.colorButtonBackground} 50%, ${theme.colorHeaderBackground} 50%)`
    }
  },
  computed: {
    ...mapGetters({
      Themes: 'StateThemes',
      Chart: 'StateSelectedChart'
    }),
    activeTheme() {
      if(this.Chart && this.Themes) {
        let allThemes = this.Themes.concat(this.defaultThemes)
        let selectedTheme = allThemes.find(x => {
          return (x['colorHeaderText'] === this.Chart.colorHeaderText)
              && (x['colorHeaderBackground'] === this.Chart.colorHeaderBackground)
              && (x['colorRowText'] === this.Chart.colorRowText)
              && (x['colorOddRowBackground'] === this.Chart.colorOddRowBackground)
              && (x['colorEvenRowBackground'] === this.Chart.colorEvenRowBackground)
              && (x['colorTopPickBackground'] === this.Chart.colorTopPickBackground)
              && (x['colorButtonText'] === this.Chart.colorButtonText)
              && (x['colorButtonBackground'] === this.Chart.colorButtonBackground)
        })
        if(selectedTheme === undefined) {
          return this.defaultThemes[this.Chart.theme]
        }
        return selectedTheme
      }

      return null

    }
  }
}
</script>

