<template>
  <div class="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
    <div class="md:grid md:grid-cols-3 md:gap-6">
      <div class="md:col-span-1">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Change Password</h3>
        <p class="mt-1 text-sm text-gray-500">
          Update your current password
        </p>
      </div>
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="submit">
          <div class="grid grid-cols-6 gap-6">

            <div class="col-span-6 sm:col-span-4">
              <label for="old_password" class="block text-sm font-medium text-gray-700">Old Password</label>
              <input
                  v-model="form.oldPassword"
                  type="password"
                  name="old_password"
                  id="old_password"
                  autocomplete="email"
                     class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm
                     border-gray-300 rounded-md"/>
              <span class="text-red-500 text-sm">{{this.oldPasswordError}}</span>
            </div>
            <div class="col-span-6 sm:col-span-4">
              <label for="password1" class="block text-sm font-medium text-gray-700">New Password</label>
              <input
                  v-model="form.password1"
                  type="password"
                  name="password1"
                  id="password1"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm
                  border-gray-300 rounded-md"/>
              <span class="text-red-500 text-sm">{{this.password1Error}}</span>
            </div>

            <div class="col-span-6 sm:col-span-4">
              <label for="password2" class="block text-sm font-medium text-gray-700">New Password Again</label>
              <input
                  v-model="form.password2"
                  type="password"
                  name="password2"
                  id="password2"
                  class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm
                  border-gray-300 rounded-md"/>
              <span class="text-red-500 text-sm">{{this.password2Error}}</span>
            </div>
          </div>

          <div class="pt-5">
            <div class="flex justify-end">
              <button type="submit"
                      class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm
                      font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none
                      focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Save
              </button>
            </div>
          </div>
        </form>

      </div>
    </div>
  </div>

</template>

<script>

import {mapActions} from "vuex";

export default {
  name: 'PasswordChange',
  data() {
    return {
      form: {
        oldPassword: '',
        password1: '',
        password2: ''
      },
      oldPasswordError: '',
      password1Error: '',
      password2Error: '',
    }
  },
  methods: {
    ...mapActions(
        ['UpdatePassword']
    ),
    submit() {
      this.UpdatePassword(this.form)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.oldPasswordError = ''
            this.password1Error = ''
            this.password2Error = ''
          })
          .catch(e => {
            let data = e.response.data

            if ("oldPassword" in data)
              this.oldPasswordError = data.oldPassword[0]

            if ("password1" in data)
              this.password1Error = data.password1[0]

            if ("password2" in data)
              this.password2Error = data.password2[0]

            if ("nonFieldErrors" in data)
              this.password2Error = data.nonFieldErrors[0]

          })
    }
  }
}

</script>